import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[csScrollTo]',
  standalone: true
})
export class ScrollToDirective {
  @Input() scrollDelay = 0;

  constructor(el: ElementRef) {
    el.nativeElement.addEventListener('click', () => {
      setTimeout(() => {
        this.scrollTo(el);
      }, this.scrollDelay);
    });
  }

  scrollTo(el: ElementRef) {
    const target = document.querySelector(el.nativeElement.getAttribute('csScrollTo'));
    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
    }
  }

}
